.App {
  text-align: center;
}

.App-header {
  background-color: #202124;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: white;
}

.progressBar {
  width: "10vw";
}
